import React, { useMemo } from "react"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { messages } from "setup/messages/messages"
import { useNavigate } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import useAssignmentActions from "views/assignments/components/candidates-list/hooks/useAssignmentActions"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { TrialWarningModal } from "views/assignments/components/candidates-list/components/trial-warning-modal"

const AssignmentPopUpActions = () => {
  const { assignmentId, totalItems, assignmentDetails } = useAssignment()
  const {
    searchAI,
    exportCandidatesWithOutArchive,
    exportWithFilters,
    isTrial,
    filterExistAndNotAll
  } = useAssignmentActions()
  const disabledAction = useMemo(() => !Boolean(totalItems), [totalItems])

  const { trackExportDisabled } = useTelemetry()
  const navigate = useNavigate()
  const { open: openModal } = useModal()

  const onTrial = () => {
    trackExportDisabled()
    openModal(ModalNames.Trial, <TrialWarningModal />)
  }

  let options: HeaderOption[] = [
    {
      label:
        messages.assignment.details.actions.addCandidate +
        " - " +
        messages.assignment.details.actions.quickSearch,
      onClick: () => {
        navigate(`${RouterUrl.ProfileNewContact}?assignmentId=${assignmentId}`)
      }
    },
    {
      label:
        messages.assignment.details.actions.addCandidate +
        " - " +
        messages.assignment.details.candidates.advanceSearch,
      onClick: () => {
        navigate(RouterUrl.Search + `/${assignmentId}/assignment`)
      }
    },
    {
      label:
        messages.assignment.details.actions.addCandidate +
        " - " +
        messages.assignment.details.candidates.searchAI,
      onClick: () => searchAI(),
      disabled: !assignmentDetails.isCompanyLinkedToDataPool
    },
    {
      label:
        messages.assignment.details.candidates.export +
        " " +
        messages.assignment.details.candidates.exportAll,
      onClick: () => (isTrial ? onTrial() : exportCandidatesWithOutArchive()),
      disabled: disabledAction
    },
    {
      label:
        messages.assignment.details.candidates.export +
        " " +
        messages.assignment.details.candidates.exportFilteredView,
      onClick: () => {
        isTrial ? onTrial() : exportWithFilters()
      },
      disabled: disabledAction || !filterExistAndNotAll
    }
  ]

  return (
    <>
      <ProjectActionPopUp options={options} />
    </>
  )
}

export default AssignmentPopUpActions
