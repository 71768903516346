import React from "react"
import { Formik, Form } from "formik"
import { NoteFormProps } from "./types"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { messages } from "setup/messages/messages"
import {
  NoteField,
  initialDefaultNoteState,
  noteValidationSchema
} from "./definitions"
import { NoteFormWrapper } from "./styles"
import { spacing } from "@ikiru/talentis-fpc"

const NoteForm = ({ formActionsComponent, note, onSubmit }: NoteFormProps) => {
  return (
    <NoteFormWrapper>
      <Formik
        initialValues={{
          ...initialDefaultNoteState,
          ...(note || {})
        }}
        validationSchema={noteValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              {formActionsComponent?.(formik)}
              <FormikInput
                id={NoteField.NoteTitle}
                name={NoteField.NoteTitle}
                label={messages.person.note.form.noteTitle}
              />
              <FormikTextarea
                style={{ resize: "none", marginTop: spacing.m }}
                rows={3}
                id={NoteField.NoteDescription}
                name={NoteField.NoteDescription}
                label={messages.person.note.form.noteDescription}
              />
            </Form>
          )
        }}
      </Formik>
    </NoteFormWrapper>
  )
}

export default React.memo(NoteForm)
