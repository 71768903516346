import { Conditions } from "./InternalSearchModule/types"

export enum InternalSearchCriteriaField {
  SectionBundle = "sections",
  SectionName = "sections.{0}.id",
  SectionCondition = "sections.{0}.condition",
  FieldBundle = "sections.{0}.fields",
  Field = "sections.{0}.fields.{0}.id",
  Boolean = "sections.{0}.fields.{0}.condition",
  Values = "sections.{0}.fields.{0}.values",
  SectionsBundleSearchUsingANDLogic = "condition"
}

export const initialValues = {
  [InternalSearchCriteriaField.SectionBundle]: [],
  [InternalSearchCriteriaField.SectionName]: "",
  [InternalSearchCriteriaField.SectionCondition]: Conditions.AND,
  [InternalSearchCriteriaField.FieldBundle]: [],
  [InternalSearchCriteriaField.Field]: "",
  [InternalSearchCriteriaField.Boolean]: "beginWith",
  [InternalSearchCriteriaField.Values]: [],
  [InternalSearchCriteriaField.SectionsBundleSearchUsingANDLogic]:
    Conditions.AND
}
