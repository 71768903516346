import React from "react"
import { Flex, LargeText } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { useNavigate } from "react-router-dom"
import { SearchCard } from "components/SearchCard"
import AdvancedSearch from "assets/advanced_search.svg"
import QuickSearch from "assets/quick_search.svg"

export const ContactsEmptyList = () => {
  const navigate = useNavigate()

  const { campaignId } = useCampaign()

  const quickSearch = () => {
    navigate(`${RouterUrl.ProfileNewContact}?campaignId=${campaignId}`)
  }

  const advancedSearch = () => {
    navigate(RouterUrl.Search + `/${campaignId}/campaign`)
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      maxWidth="60%"
      alignItems="center"
      m="auto"
      textAlign="center"
      py="xl"
    >
      <LargeText mt="none" mb="s" color="grey.dark" fontWeight={600}>
        {messages.campaign.details.noContacts}
      </LargeText>
      <Flex>
        <SearchCard
          cardImg={QuickSearch}
          title={messages.campaign.details.quickSearch}
          description={messages.campaign.details.quickSearchDescription}
          onSelectCard={quickSearch}
        />
        <SearchCard
          cardImg={AdvancedSearch}
          title={messages.campaign.details.advancedSearch}
          description={messages.campaign.details.advancedSearchDescription}
          onSelectCard={advancedSearch}
        />
      </Flex>
    </Flex>
  )
}
