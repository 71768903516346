import React, { useEffect } from "react"
import { Formik, Form } from "formik"
import { messages } from "setup/messages/messages"
import { Button, Div, Flex, PlusIcon, colors } from "@ikiru/talentis-fpc"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { contactLookupFormInitialValues } from "./definitions"
import { getLocalContactLookupResult } from "views/internal-search/actions"
import { RouterUrl } from "setup/router/routes"
import { RouterLink } from "components/RouterLink/RouterLink"
import ActionButton from "components/ProjectActions/ActionButton"
import { SearchInput } from "./SearchInput"

export const ContactLookup = () => {
  const {
    setInternalPersons,
    setIsLoadingSearchResults,
    setInternalSearchPage,
    setIsLookup,
    setLookupSearchString,
    lookupSearchString
  } = useInternalSearch()

  const onSubmit = async () => {
    setIsLoadingSearchResults(true)
    let searchResult = await getLocalContactLookupResult(lookupSearchString)
    setIsLookup(true)
    setInternalSearchPage({
      hasNextPage: searchResult.hasNextPage,
      hasPreviousPage: searchResult.hasPreviousPage,
      pageCount: searchResult.pageCount,
      pageNumber: searchResult.pageNumber,
      pageSize: searchResult.pageSize,
      totalItemCount: searchResult.totalItemCount,
      maxPages: 9
    })
    setInternalPersons(searchResult.people)
  }

  useEffect(() => {
    setIsLoadingSearchResults(true)
    setLookupSearchString({ quickSearchValue: "*" })
    const fetchLookUp = async () => {
      try {
        let searchResult = await getLocalContactLookupResult({
          quickSearchValue: "*"
        })
        setIsLookup(true)
        setInternalSearchPage({
          hasNextPage: searchResult.hasNextPage,
          hasPreviousPage: searchResult.hasPreviousPage,
          pageCount: searchResult.pageCount,
          pageNumber: searchResult.pageNumber,
          pageSize: searchResult.pageSize,
          totalItemCount: searchResult.totalItemCount,
          maxPages: 9
        })
        setInternalPersons(searchResult.people)
      } catch (error) {
        console.error("Failed to fetch fields:", error)
      }
    }

    fetchLookUp()
  }, [])

  return (
    <Flex
      p="xs"
      bg={colors.grey.light}
      justifyContent="space-between"
      alignItems="center"
    >
      <Formik
        initialValues={contactLookupFormInitialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <Flex flexWrap="wrap" alignItems="center">
            <SearchInput />
            <Button
              mt={["xs", "none"]}
              type="submit"
              mode="standard-green"
              size="small"
              disabled={!lookupSearchString.quickSearchValue}
            >
              {messages.form.generic.contactLookup}
            </Button>
          </Flex>
        </Form>
      </Formik>
      <Div>
        <RouterLink to={RouterUrl.InternalSearchNewContact}>
          <ActionButton
            type="button"
            mode="standard-green"
            prefix={
              <PlusIcon width={12} height={12} fill={colors.white.standard} />
            }
            size="small"
            label={messages.searchPerson.addContact}
          />
        </RouterLink>
      </Div>
    </Flex>
  )
}
