import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from "react"
import { get } from "lodash"
import { SearchContext } from "./context"
import { VISIBLE_PAGES } from "./consts"
import { searchReducer, initialSearchState } from "./reducer"
import {
  AddArrayBasedFilter,
  FilterType,
  RemoveUpdatingPerson,
  ResetFilters,
  SavedSearches,
  SearchActionTypes,
  SearchResponse,
  SetActiveAssignments,
  SetFiltersExpanded,
  SetIsCriteriaNotDefined,
  SetIsLoading,
  SetPage,
  SetPersons,
  SetPersonUpdating,
  SetRecordsCount,
  SetSelectedAssignment,
  UpdateFilters,
  UpdatePerson,
  SetIsLoadingSeaches,
  SetSelectedSearchPersonId,
  SearchPersonWithAssignment,
  SetActiveCampaigns,
  SetSelectedCampaign,
  SetIsAssignmentSelect,
  SetIsAnonymized,
  SetHasResetFilters,
  SetSelectedSearchDataPoolId
} from "./types"
import { apiRequest, CancelToken, isRequestCancelled } from "setup/api/api"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints,
  CandidatesEndpoints,
  ContactsEndpoints,
  PersonsEndpoints,
  SearchEndpoints,
  SearchFirmEndpoints
} from "setup/api/endpoints/endpoints"
import {
  encodePerson,
  saveFiltersToSessionStorage,
  getFiltersFromSessionStorage,
  hasFiltersInSessionStorage,
  prepareTalentGraphFilters,
  capitalizeFirstLetter
} from "./utils"
import { CancelTokenSource } from "axios"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { CandidateAddedFrom } from "setup/app-insights/definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { Stage } from "views/persons/person.types"
import { Campaign } from "views/campaigns/campaign.types"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import { fetchPersonPhotos } from "models/LocalPerson/localPerson.actions"
import { useParams } from "react-router-dom"
import { ProjectParams } from "../components/ProjectSelector"

type SearchModuleProps = {
  children: React.ReactNode
}

export const SearchModule = (props: SearchModuleProps) => {
  const { children } = props
  const currentSearchRequest = useRef<CancelTokenSource>()
  const { trackAddCandidate } = useTelemetry()

  const [state, dispatch] = useReducer(searchReducer, initialSearchState)

  const setIsLoading: SetIsLoading = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.SetIsLoading, payload: data })
    },
    [dispatch]
  )

  const setIsFinishedLoadingSearches: SetIsLoading = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetIsFinishedLoadingSeaches,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsCriteriaNotDefined: SetIsCriteriaNotDefined = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetIsCriteriaNotDefined,
        payload: data
      })
    },
    [dispatch]
  )

  const setPage: SetPage = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetPage,
        payload: data
      })
    },
    [dispatch]
  )

  const setActiveAssignments: SetActiveAssignments = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetActiveAssignments,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedAssignment: SetSelectedAssignment = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetSelectedAssignment,
        payload: data
      })
    },
    [dispatch]
  )

  const setRecordsCount: SetRecordsCount = useCallback(
    (totalItems, totalPages) => {
      dispatch({
        type: SearchActionTypes.SetRecordsCount,
        payload: { totalItems, totalPages }
      })
    },
    [dispatch]
  )

  const setIsAnonymized: SetIsAnonymized = useCallback(
    (isAnonimized) => {
      dispatch({
        type: SearchActionTypes.SetIsAnonymized,
        payload: isAnonimized
      })
    },
    [dispatch]
  )

  const setPersonsFound: SetPersons = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SetPersons,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsLoadingSearches: SetIsLoadingSeaches = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.SetIsLoadingSeaches, payload: data })
    },
    [dispatch]
  )

  const updatePerson: UpdatePerson = useCallback(
    (newPersons: SearchPersonWithAssignment[]) => {
      dispatch({
        type: SearchActionTypes.UpdatePerson,
        payload: newPersons
      })
    },
    [dispatch]
  )

  const updateFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.UpdateFilters, payload: data })
    },
    [dispatch]
  )

  const clearFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({ type: SearchActionTypes.ClearCertainFilters, payload: data })
    },
    [dispatch]
  )

  const setPersonUpdating: SetPersonUpdating = useCallback(
    (key: string) => {
      dispatch({
        type: SearchActionTypes.SetPersonUpdating,
        payload: key
      })
    },
    [dispatch]
  )

  const setFiltersExpanded: SetFiltersExpanded = useCallback(
    (key: boolean) => {
      dispatch({
        type: SearchActionTypes.SetFiltersExpanded,
        payload: key
      })
    },
    [dispatch]
  )

  const removeUpdatingPerson: RemoveUpdatingPerson = useCallback(
    (key: string) => {
      dispatch({
        type: SearchActionTypes.RemoveUpdatingPerson,
        payload: { key }
      })
    },
    [dispatch]
  )

  const resetFilters: ResetFilters = useCallback(() => {
    dispatch({
      type: SearchActionTypes.ResetFilters
    })
  }, [dispatch])

  const setLoadSearches: SavedSearches = useCallback(
    (data) => {
      dispatch({
        type: SearchActionTypes.SavedSearch,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedSearchPersonId: SetSelectedSearchPersonId = useCallback(
    (personId: string) => {
      dispatch({
        type: SearchActionTypes.SetSelectedSearchPersonId,
        payload: personId
      })
    },
    [dispatch]
  )

  const setSelectedSearchDataPoolId: SetSelectedSearchDataPoolId = useCallback(
    (personId: string) => {
      dispatch({
        type: SearchActionTypes.SetSelectedSearchDataPoolId,
        payload: personId
      })
    },
    [dispatch]
  )

  const setActiveCampaigns: SetActiveCampaigns = useCallback(
    (data: Campaign[]) => {
      dispatch({
        type: SearchActionTypes.SetActiveCampaigns,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedCampaign: SetSelectedCampaign = useCallback(
    (data: string) => {
      dispatch({
        type: SearchActionTypes.SetSelectedCampaign,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsAssignmentSelect: SetIsAssignmentSelect = useCallback(
    (data: boolean) => {
      dispatch({
        type: SearchActionTypes.SetIsAssignmentSelect,
        payload: data
      })
    },
    [dispatch]
  )

  const setHasResetFilters: SetHasResetFilters = useCallback(
    (data: boolean) => {
      dispatch({
        type: SearchActionTypes.SetHasResetFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const addArrayBasedFilter = useCallback(
    ({ filterName, value, limit, callback }: AddArrayBasedFilter) => {
      const values = !Array.isArray(value) ? [value] : value

      const trimmedValues = values.map((v) => v.trim()).filter((v) => v !== "")

      const currentValue = get(state.filters, filterName, []) as string[]

      if (limit && currentValue.length >= limit) {
        callback?.()
        return
      }

      let newValue: string[] = [...currentValue]
      trimmedValues.forEach((v) => {
        if (!currentValue.includes(v)) {
          newValue = [...newValue, v]
        }
      })

      updateFilters({
        [filterName]: newValue
      })
    },

    [state.filters, updateFilters]
  )

  const removeArrayBasedFilter = useCallback(
    (filterName: FilterType | string, value: string) => {
      const currentValue = get(state.filters, filterName, []) as string[]
      updateFilters({
        [filterName]: currentValue.filter((v: string) => v !== value)
      })
    },
    [state.filters, updateFilters]
  )

  const clearArrayBasedFilter = useCallback(
    (filterName: FilterType | string) => {
      const currentValue = get(state.filters, filterName, []) as string[]

      clearFilters({
        [filterName]: currentValue
      })
    },
    [state.filters, clearFilters]
  )

  const addingNewAssignment = useCallback(
    (assignmentId: string) => setSelectedAssignment(assignmentId),
    [setSelectedAssignment]
  )

  const fetchAssignments = useCallback(
    async (isAdding = false) => {
      const [, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300 } }
      })

      const assignments = response?.data?.simpleActiveAssignments || []

      setActiveAssignments(assignments)
      if (isAdding) addingNewAssignment(assignments[0].id)
    },
    [setActiveAssignments, addingNewAssignment]
  )

  const setHasStoredFilters = useCallback(
    (hasStoredFilters: boolean) => {
      dispatch({
        type: SearchActionTypes.SetHasStoredFilters,
        payload: hasStoredFilters
      })
    },
    [dispatch]
  )

  const searchTalentGraph = async () => {
    const data = prepareTalentGraphFilters(state.filters)

    if (!data) {
      setIsCriteriaNotDefined(true)
      resetFilters()
      return
    }

    saveFiltersToSessionStorage(state.filters)
    setHasStoredFilters(true)

    setIsCriteriaNotDefined(false)
    setIsLoading(true)

    const cancelToken = CancelToken.source()

    currentSearchRequest?.current?.cancel()
    currentSearchRequest.current = cancelToken

    const [err, response] = await apiRequest.post<SearchResponse>({
      endpoint: SearchEndpoints.ByQuery,
      data: {
        ...data,
        pageSize: state.pageSize,
        pageNumber: state.pageNumber
      },
      config: {
        cancelToken: cancelToken.token,
        headers: {
          ...skipErrorHeader
        }
      }
    })

    if (isRequestCancelled(err)) {
      return
    }
    if (response?.data) {
      const {
        personsWithAssignmentIds = [],
        totalItemCount = 0,
        pageCount = 0
      } = response.data

      const personIdFilters = await personsWithAssignmentIds.filter(
        (person) => person.person?.personId !== null
      )
      const personIds = personIdFilters.map((person) => person.person?.personId)
      const [, campaignRes] = await apiRequest.post<SearchResponse>({
        endpoint: SearchEndpoints.GetCampaignIds,
        data: {
          personIds: personIds
        }
      })
      const campaignIds = campaignRes?.data.personsWithCampaignIds

      const personsWithCampaignIds = personsWithAssignmentIds.map((persons) => {
        const hasCampaigns = campaignIds?.find(
          (campaign) => campaign.key === persons.person?.personId
        )
        if (hasCampaigns) {
          persons.campaignIds = hasCampaigns.value
        }
        return persons
      })
      const personIdList =
        personsWithCampaignIds
          .filter((person) => Boolean(person.person.personId))
          .map((person) => person.person.personId!) || []

      const photoList = await fetchPersonPhotos(personIdList)

      const personWithPhoto = personsWithCampaignIds.map((person) => {
        const photoUrl = photoList.find(
          (photo) => photo.personId === person.person.personId
        )?.photo

        if (photoUrl) {
          person.person["photo"] = { url: photoUrl }
        }
        return person
      })
      setRecordsCount(totalItemCount, pageCount)
      setPersonsFound(personWithPhoto)
      return
    }

    setIsLoading(false)
  }

  const loadPreviousSearch = useCallback(() => {
    const sessionStorageFilters = getFiltersFromSessionStorage()

    if (sessionStorageFilters) {
      updateFilters(sessionStorageFilters)
    }
  }, [updateFilters])

  const loadExamplesSearch = useCallback(
    (examplesSearch: any) => {
      updateFilters(examplesSearch)
    },
    [updateFilters]
  )

  const fetchSearches = useCallback(
    async (data?: boolean) => {
      if (data) setIsLoadingSearches(true)
      const [, response] = await apiRequest.get({
        endpoint: SearchFirmEndpoints.Searches,
        config: { params: { totalItemCount: 300 } }
      })

      const savedSearches = response?.data?.searches || []

      setLoadSearches(savedSearches)
      setIsFinishedLoadingSearches(true)
      if (data) setIsLoadingSearches(false)
    },
    [setLoadSearches, setIsLoadingSearches, setIsFinishedLoadingSearches]
  )

  const addingNewCampaign = useCallback(
    (campainId: string) => setSelectedCampaign(campainId),
    [setSelectedCampaign]
  )

  const fetchCampaigns = useCallback(
    async (isAdding = false) => {
      const [, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300, status: "active" } }
      })

      const campaigns = response?.data?.simpleActiveCampaigns
      setActiveCampaigns(campaigns)
      if (isAdding) addingNewCampaign(campaigns[0].id)
    },
    [setActiveCampaigns, addingNewCampaign]
  )

  useEffect(() => {
    searchTalentGraph()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pageNumber])

  useEffect(() => {
    fetchAssignments()
  }, [fetchAssignments])

  useEffect(() => {
    fetchSearches()
  }, [fetchSearches])

  useEffect(() => {
    fetchCampaigns()
  }, [fetchCampaigns])

  useEffect(() => {
    const hasStoredFilters = hasFiltersInSessionStorage()
    setHasStoredFilters(hasStoredFilters)
  }, [setHasStoredFilters])

  useEffect(() => {
    const filtersAI = sessionStorage.getItem(sessionStorageKeys.aIFilters)

    if (filtersAI) {
      updateFilters(JSON.parse(filtersAI))

      setTimeout(() => {
        sessionStorage.removeItem(sessionStorageKeys.aIFilters)
      }, 3000)
    }
  }, [searchTalentGraph, updateFilters])

  // useEffect(() => {
  //   if (!isEmpty(state.filters)) {
  //     saveFiltersToSessionStorage(state.filters)
  //     setHasStoredFilters(true)
  //   }
  // }, [state.filters, setHasStoredFilters])
  const { typeOfProject } = useParams<ProjectParams>()
  useEffect(() => {
    let settingUrl = true
    if (state.isAssignmentSelect === null) {
      if (typeOfProject === "campaign") {
        setIsAssignmentSelect(false)
      } else {
        setIsAssignmentSelect(true)
      }
    }

    if (
      (state.selectedAssignment && state.isAssignmentSelect) ||
      (state.selectedCampaign && !state.isAssignmentSelect)
    ) {
      const projectIdParam = state.isAssignmentSelect
        ? state.selectedAssignment
        : state.selectedCampaign
      const typeOfProjectParam = state.isAssignmentSelect
        ? "assignment"
        : "campaign"
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        `/search/${projectIdParam}/${typeOfProjectParam}`
      if (settingUrl) {
        window.history.pushState({ path: newurl }, "", newurl)
      }
    } else {
      const newurl =
        window.location.protocol + "//" + window.location.host + `/search`
      if (settingUrl) {
        window.history.pushState({ path: newurl }, "", newurl)
      }
    }

    return () => {
      settingUrl = false
    }
  }, [
    setIsAssignmentSelect,
    state.isAssignmentSelect,
    state.selectedAssignment,
    state.selectedCampaign,
    typeOfProject
  ])

  const paginationProps = useMemo(
    () => ({
      hasNextPage: state.pageNumber < state.totalPages,
      hasPreviousPage: state.pageNumber > 1,
      maxPages: VISIBLE_PAGES,
      pageCount: state.totalPages,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize,
      totalItemCount: state.totalItems
    }),
    [state.pageNumber, state.pageSize, state.totalItems, state.totalPages]
  )

  const linkageDataPoolPerson = useCallback(
    async (dataPoolPersonId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: PersonsEndpoints.DataPoolLinkage,
        data: { dataPoolPersonId }
      })

      if (response) {
        const localPersonId = response?.data?.localPerson?.id
        return localPersonId
      }
      return undefined
    },
    []
  )

  const addAssignmentToPerson = useCallback(
    async (personId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: CandidatesEndpoints.Root,
        data: {
          assignmentId: state.selectedAssignment,
          personId: personId
        }
      })

      if (response) {
        trackAddCandidate({
          addedFrom: CandidateAddedFrom.MainSiteSearch,
          assignmentId: state.selectedAssignment,
          localPersonId: personId
        })
      }

      return response?.data?.assignmentId
    },
    [state.selectedAssignment, trackAddCandidate]
  )

  const addCampaignToPerson = useCallback(
    async (personId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: ContactsEndpoints.Root,
        data: {
          campaignId: state.selectedCampaign,
          personId: personId
        }
      })

      return response?.data?.campaignId
    },
    [state.selectedCampaign]
  )

  const { persons } = state

  const updatePersonsAssignment = useCallback(
    (localPersonId: any, assignId: any, dataPoolPersonId: any) => {
      const personIndex = persons
        ? persons.findIndex((p: any) =>
            dataPoolPersonId
              ? p.person?.dataPoolId === dataPoolPersonId
              : p.person?.personId === localPersonId
          )
        : -1

      if (persons && personIndex >= 0) {
        const personObj = persons[personIndex]

        personObj.person!.personId = localPersonId
        if (state.isAssignmentSelect) {
          personObj.assignmentIds.push(assignId)
          personObj.stages.push({ name: "Identified", count: 1 })
        } else {
          personObj.campaignIds
            ? personObj.campaignIds.push(assignId)
            : (personObj.campaignIds = [assignId])
        }

        updatePerson(persons)
      }
    },
    [persons, updatePerson, state.isAssignmentSelect]
  )

  const updatePersonsStage = useCallback(
    (localPersonId: any, oldStage: any, stage: any) => {
      const stageTemp = capitalizeFirstLetter(stage)
      const oldStageTemp = capitalizeFirstLetter(oldStage)

      const personIndex = persons.findIndex(
        (p: any) => p.person?.personId === localPersonId
      )

      if (persons && personIndex >= 0) {
        const personObj = persons[personIndex]

        if (!personObj.stages.find((item: Stage) => stageTemp === item.name)) {
          personObj.stages.push({ name: stageTemp, count: 1 })
          personObj.stages.map(
            (item: Stage) => item.name === oldStageTemp && item.count--
          )
        } else {
          personObj.stages.map((item: Stage) => {
            if (item.name === stageTemp) {
              item.count++
            } else if (item.name === oldStageTemp) {
              item.count--
            }
            return null
          })
        }

        updatePerson(persons)
      }
    },
    [persons, updatePerson]
  )

  const updatePersonsCampaign = useCallback(
    (localPersonId: any, campaignId: any, dataPoolPersonId: any) => {
      const personIndex = persons
        ? persons.findIndex((p: any) =>
            dataPoolPersonId
              ? p.person?.dataPoolId === dataPoolPersonId
              : p.person?.personId === localPersonId
          )
        : -1

      if (persons && personIndex >= 0) {
        const personObj = persons[personIndex]

        personObj.person!.personId = localPersonId
        personObj.campaignIds
          ? personObj.campaignIds.push(campaignId)
          : (personObj.campaignIds = [campaignId])

        updatePerson(persons)
      }
    },
    [persons, updatePerson]
  )

  const assignAPerson = useCallback(
    (localId: string) => {
      const data = state.isAssignmentSelect
        ? addAssignmentToPerson(localId)
        : addCampaignToPerson(localId)
      return data
    },
    [addAssignmentToPerson, addCampaignToPerson, state.isAssignmentSelect]
  )

  const handleLocalPerson = useCallback(
    async (localPersonId: string, dataPoolPersonId: string) => {
      const personKey = encodePerson(localPersonId, dataPoolPersonId)

      setPersonUpdating(personKey)

      const assignId = await assignAPerson(localPersonId)

      if (assignId)
        updatePersonsAssignment(localPersonId, assignId, dataPoolPersonId)

      removeUpdatingPerson(personKey)
    },
    [
      assignAPerson,
      removeUpdatingPerson,
      setPersonUpdating,
      updatePersonsAssignment
    ]
  )

  const handleDataPoolPerson = useCallback(
    async (dataPoolPersonId: string) => {
      const personKey = encodePerson(null, dataPoolPersonId)

      setPersonUpdating(personKey)

      const localPersonId = await linkageDataPoolPerson(dataPoolPersonId)
      const assignId = localPersonId ? await assignAPerson(localPersonId) : null

      if (localPersonId || assignId)
        updatePersonsAssignment(localPersonId, assignId, dataPoolPersonId)

      removeUpdatingPerson(personKey)
    },
    [
      assignAPerson,
      linkageDataPoolPerson,
      removeUpdatingPerson,
      setPersonUpdating,
      updatePersonsAssignment
    ]
  )

  const handleSaveSearch = useCallback(
    async (values: any, filters: any) => {
      const [, response] = await apiRequest.post({
        endpoint: SearchFirmEndpoints.Searches,
        data: {
          name: values.name,
          description: values.description,
          query: filters,
          isDefault: Boolean(values.defaultSearch)
        }
      })

      if (response) {
        fetchSearches()
      }
    },
    [fetchSearches]
  )

  const removeSavedSearch = useCallback(
    async (searchId: string) => {
      const [error] = await apiRequest.delete({
        endpoint: SearchFirmEndpoints.Searches,
        endpointParams: searchId
      })

      !error && fetchSearches(true)
    },
    [fetchSearches]
  )

  const setDefaultSearch = useCallback(
    async (searchId: string, isDefault: boolean) => {
      const [error] = await apiRequest.put({
        endpoint: SearchFirmEndpoints.SetDefaultSearch,
        endpointParams: searchId,
        data: {
          isDefault
        }
      })

      !error && fetchSearches(true)
    },
    [fetchSearches]
  )

  const getPreparedFilters = () => prepareTalentGraphFilters(state.filters)

  return (
    <SearchContext.Provider
      value={{
        ...state,
        paginationProps,
        getPreparedFilters,
        setSelectedAssignment,
        onPageChange: setPage,
        handleLocalPerson,
        handleDataPoolPerson,
        linkageDataPoolPerson,
        addArrayBasedFilter,
        removeArrayBasedFilter,
        clearArrayBasedFilter,
        clearFilters,
        setFiltersExpanded,
        updateFilters,
        resetFilters,
        loadPreviousSearch,
        loadExamplesSearch,
        handleSaveSearch,
        fetchSearches,
        removeSavedSearch,
        setDefaultSearch,
        setSelectedSearchPersonId,
        setSelectedSearchDataPoolId,
        updatePerson,
        updatePersonsStage,
        updatePersonsAssignment,
        setSelectedCampaign,
        setIsAssignmentSelect,
        setIsAnonymized,
        fetchAssignments,
        fetchCampaigns,
        updatePersonsCampaign,
        searchTalentGraph,
        setHasResetFilters
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
