import React, { useMemo } from "react"
import { Span } from "@ikiru/talentis-fpc"
import { Experience } from "views/persons/person.types"
import moment from "moment"
import { messages } from "setup/messages/messages"
import { formatDate } from "utils/format-date"
import { AllExperienceDatesType } from "views/internal-search/InternalSearchModule/types"

type ExperienceProps = {
  allExperienceDates: AllExperienceDatesType[]
}

export const YearsExperience = ({
  allExperienceDates = []
}: ExperienceProps) => {
  const yearsExperience = useMemo(() => {
    const allPersonJobs = allExperienceDates
    if (!allPersonJobs.length) return null
    const sDateFormat = allPersonJobs.map((job: AllExperienceDatesType) => {
      return job.startDate && formatDate(job.startDate)
    })
    const eDate = allPersonJobs
      .map((job: AllExperienceDatesType) =>
        !job.endDate
          ? moment().format("MM/DD/YYYY")
          : job.endDate && formatDate(job.endDate)
      )
      .sort((a: string | undefined, b: string | undefined) =>
        moment(b).diff(moment(a), "hours")
      )
    const sDate = sDateFormat.sort((a: string | null, b: string | null) =>
      moment(a).diff(moment(b), "hours")
    )
    const diff = moment(eDate[0]).diff(moment(sDate[0]), "years")
    return diff
      ? diff < 25
        ? `(${messages.person.personRecordBox.atLeast} ${diff} ${messages.person.personRecordBox.yearsExp})`
        : `(${25} + ${messages.person.personRecordBox.yearsExp})`
      : ""
  }, [allExperienceDates])

  return (
    <Span py="5px" fontSize="13px">
      {yearsExperience}
    </Span>
  )
}
