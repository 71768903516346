import React from "react"
import { H5 } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { browserExtensionDownloadUrl } from "./helpers"

export const RedirectToBExtAlert = () => (
  <H5 my={0} color="orange.darkest">
    <Link
      href={browserExtensionDownloadUrl}
      target="_blank"
      fontWeight={600}
      variant="orange-white"
    >
      {messages.browserExtension.install}
    </Link>{" "}
    - {messages.browserExtension.findAndEngageWithCandidates}
  </H5>
)
