import { colors } from "@ikiru/talentis-fpc"
import React from "react"
import Select from "react-select"
import MenuComponent from "./MenuComponent"

type CustomSelectProp = {
  label: string
  disabled: boolean
  options: object[]
  defaultValue?: object
  value: any
  onChange: (e: any) => void
  isSearchable: boolean
}

const CustomSelect = ({
  label,
  disabled,
  options,
  defaultValue,
  value,
  onChange,
  isSearchable
}: CustomSelectProp) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 150,
      height: 25,
      minHeight: 20,
      maxHeight: 25,
      borderRadius: 0,
      padding: 4,
      borderStyle: "solid",
      borderWidth: "0 0 2px 0",
      borderColor: colors?.grey?.light,
      boxShadow: "none !important",
      "&:hover": {
        color: colors?.grey?.dark,
        borderColor: colors?.grey?.standard
      }
    }),
    singleValue: () => ({
      fontWeight: 500,
      fontFamily: "canada-type-gibson",
      color: colors.grey.dark,
      fontSize: 13,
      overflow: "hidden"
    }),
    valueContainer: () => ({
      minHeight: 20,
      maxHeight: 25,
      maxWidth: "85%"
    }),
    input: () => ({
      display: "none"
    }),
    dropdownIndicator: () => ({
      maxHeight: 25,
      height: 25,
      padding: 0,
      color: colors.grey.dark,
      width: 18,
      maxWidth: 18
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      padding: 0,
      background: colors?.grey?.lightest,
      color: colors?.grey?.dark,
      borderWidth: 1,
      right: 0,
      maxHeight: 800,
      width: "",
      overflow: "hidden",
      border: `1px solid ${colors.grey.light}`,
      paddingTop: 2,
      "@media (max-width: 450px)": {
        width: "340px"
      },
      "@media (min-width: 451px) and (max-width: 768px)": {
        width: "450px"
      }
    }),
    menuList: () => ({
      padding: 0
    }),
    option: (provided: any, state: any) => ({
      paddingRight: 10,
      paddingLeft: 5,
      cursor: "pointer",
      height: 20,
      maxHeight: 22,
      top: 0,
      left: 0,
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "canada-type-gibson",
      color: state?.data?.disabled
        ? "grey"
        : state.isSelected
        ? colors.white.standard
        : colors.grey.dark,
      letterSpacing: 0.1,
      backgroundColor:
        state.isSelected && !state.data.disabled ? "#1E90FF" : "none",
      "&:hover": {
        color: colors?.white.standard,
        backgroundColor: state.data?.disabled ? "lightgrey" : "#1E90FF"
      }
    })
  }

  const formatOptionLabel = ({ label }: any) => (
    <div style={{ display: "flex", whiteSpace: "nowrap" }}>{label}</div>
  )

  return (
    <Select
      aria-label={label}
      isDisabled={disabled}
      options={options}
      defaultValue={defaultValue}
      value={value}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      styles={customStyles}
      isSearchable={isSearchable}
      captureMenuScroll={false}
      components={{ MenuList: MenuComponent, IndicatorSeparator: () => null }}
    />
  )
}

export default CustomSelect
