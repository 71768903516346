import React, { useEffect, useMemo } from "react"
import { Div, Loader, Microphone } from "@ikiru/talentis-fpc"
import { StyledMicrophoneContainer } from "./styles"
import { useSpeechToText } from "utils/hooks/use-speech-to-text"
import { FormikErrors } from "formik"

type StyledMicrophoneProps = {
  value: string
  setValue: (
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>
  children: (onUserTyping: () => void) => JSX.Element | boolean
}
const StyledMicrophone = ({
  value,
  setValue,
  children
}: StyledMicrophoneProps) => {
  const {
    isListening,
    stopListening,
    toggleMic,
    previewText,
    isLoading: isLoadingMic
  } = useSpeechToText()

  const initialValue = useMemo(() => value + " ", [value])

  useEffect(() => {
    if (isListening && !isLoadingMic) {
      setValue(previewText)
    }
  }, [isListening, isLoadingMic, previewText, setValue])

  const onUserTyping = () => {
    if (isListening) {
      stopListening()
    }
  }

  return (
    <Div position="relative">
      {children(onUserTyping)}
      {"webkitSpeechRecognition" in window && (
        <StyledMicrophoneContainer
          isListening={isListening || false}
          onClick={() => toggleMic && toggleMic(initialValue)}
        >
          {isLoadingMic ? (
            <Loader size="small" style={{ opacity: "0.8" }} />
          ) : (
            <Microphone width="20px" />
          )}
        </StyledMicrophoneContainer>
      )}
    </Div>
  )
}

export default StyledMicrophone
