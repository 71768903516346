import React from "react"
import { FlexItemStyled, PersonalInfoWrapper } from "./styles"
import { ExperienceSection } from "./ExperienceSection"
import { PersonContacts } from "./PersonContacts"
//import { PersonActivities } from "./PersonActivities"
import { PersonFooter } from "./PersonFooter"
import { PersonInfo } from "./PersonInfo"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"

type PersonRecordProps = {
  person: InternalPersonType
}

const PersonRecord = ({ person }: PersonRecordProps) => {
  const { note } = usePersonRecordNotes(person.id)
  return (
    <>
      <PersonalInfoWrapper>
        <FlexItemStyled isOfflimits={person?.isOfflimits}>
          <PersonInfo person={person} />
        </FlexItemStyled>
        <FlexItemStyled>
          {person.latestExperience && (
            <ExperienceSection experience={person.latestExperience} />
          )}
        </FlexItemStyled>

        <FlexItemStyled>
          <PersonContacts
            taggedEmails={person?.taggedEmails || []}
            phones={person?.phones || []}
          />
        </FlexItemStyled>

        <FlexItemStyled>
          {/* <PersonActivities assignmentCount={0} campaignCount={3} /> */}
        </FlexItemStyled>
      </PersonalInfoWrapper>

      <PersonFooter bio={person.bio} note={note} />
    </>
  )
}

export default PersonRecord
