import React, { useEffect } from "react"
import { ToggleButton, ToggleButtonGroup } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useInternalSearch } from "../InternalSearchModule/context"
import { useToggleButtonSection } from "../hooks"
import { Conditions, InternalFilterType } from "../InternalSearchModule/types"

const filterType = InternalFilterType.SectionsBundleSearchUsingANDLogic

export const LogicalConnection = () => {
  const { filters, updateFilters } = useInternalSearch()

  const onChange = useToggleButtonSection(filterType)

  const sectionLogic = filters?.[filterType] || Conditions.AND

  useEffect(() => {
    !filters?.[filterType] && updateFilters({ [filterType]: Conditions.AND })
  }, [updateFilters, filters, filterType])

  return (
    <ToggleButtonGroup
      required
      exclusive
      onChange={onChange}
      value={sectionLogic}
    >
      <ToggleButton
        label={messages.talentGraphSearch.logic.and}
        value={Conditions.AND}
        type="button"
      />
      <ToggleButton
        label={messages.talentGraphSearch.logic.or}
        value={Conditions.OR}
        type="button"
      />
    </ToggleButtonGroup>
  )
}
