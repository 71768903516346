import React from "react"
import { messages } from "setup/messages/messages"
import { Flex, H2, colors } from "@ikiru/talentis-fpc"
import InternalSearchCriteria from "./components/InternalSearchCriteria"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import InternalSearchResults from "./components/InternalSearchResults"
import { useInternalSearch } from "./InternalSearchModule/context"
import { ContactLookup } from "./components/ContactLookup/ContactLookup"

export const InternalSearchPage = () => {
  const { isLoadingSearchResults, isResetFilters } = useInternalSearch()
  return (
    <Flex flexDirection="column" width="100%">
      <H2 color={colors.green.standard} mt="0">
        {messages.internalSearch.title}
      </H2>
      <ContactLookup />

      <Tabs>
        <Tab title="Search criteria" isActive={isResetFilters}>
          <InternalSearchCriteria />
        </Tab>

        <Tab title="Results" isActive={isLoadingSearchResults}>
          <InternalSearchResults />
        </Tab>
      </Tabs>
    </Flex>
  )
}
