import React, { useState } from "react"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import {
  Flex,
  DropdownArrowIcon as DropdownArrow,
  theme
} from "@ikiru/talentis-fpc"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { useNavigate } from "react-router-dom"
import ActionButton from "components/ProjectActions/ActionButton"
import RotateAction from "components/ProjectActions/RotateActionIcon"

const ContactListHeader = () => {
  const [popupOpen, setPopupOpen] = useState(false)
  const { campaignId, contacts } = useCampaign()

  const navigate = useNavigate()

  const options: HeaderOption[] = [
    {
      label: messages.assignment.details.actions.quickSearch,
      onClick: () => {
        navigate(`${RouterUrl.ProfileNewContact}?campaignId=${campaignId}`)
      }
    },
    {
      label: messages.assignment.details.candidates.advanceSearch,
      onClick: () => {
        navigate(RouterUrl.Search + `/${campaignId}/campaign`)
      }
    }
  ]

  return (
    <Flex flexDirection="column">
      <Flex ml="auto">
        {contacts.length > 0 && (
          <Flex py="xs">
            <ProjectActionPopUp
              options={options}
              config={{
                ContentProps: {
                  marginRight: -16,
                  marginTop: 5
                }
              }}
              getIfActive={setPopupOpen}
              alternativeButton={
                <ActionButton
                  size="small"
                  mode="secondary"
                  pt="xxs"
                  pb="xxs"
                  mr="xs"
                  label={messages.campaign.details.action.addContact}
                  suffix={
                    <RotateAction isActive={popupOpen}>
                      <DropdownArrow
                        fill={theme.colors.green.dark}
                        width={13}
                        height={13}
                      />
                    </RotateAction>
                  }
                />
              }
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default ContactListHeader
