import { Nullable } from "tsdef"
import { PersonConsentsFormFields } from "views/persons/components/person-consents/form/types"
import { SMTPValid } from "@ikiru/talentis-fpc"
import {
  EmailTypeEnums,
  PhoneTypeEnums
} from "./components/person-details-tab/components/person-contact-info/form/constants/definitions"
import { OffLimitsType, OfflimitsOverride } from "components/OffLimits/type"
import { TeamMember } from "../../@types/fruity-parsnip"
import { Membership } from "../../components/ProjectTeamMembers/types"

export type WebSiteType =
  | "linkedIn"
  | "xing"
  | "crunchbase"
  | "reuters"
  | "bloomberg"
  | "facebook"
  | "zoomInfo"
  | "talentis"
  | "twitter"
  | "owler"
  | "companiesHouse"
  | "youTube"
  | "other"

export type WebSite = {
  type?: WebSiteType
  url: string
  dpWebsite?: boolean
}

export type TaggedEmail = {
  email: string
  type: EmailTypeEnums | number | string
  smtpValid?: SMTPValid
  isPreferred: boolean
}

export type Employment = {
  companyName: Nullable<string>
  endDate: Nullable<Date>
  startDate: Nullable<Date>
  position: Nullable<string>
}

export type Stage = {
  name: string
  count: number
}

export type Status = {
  name: string
  count: number
}

export type PhoneDataType = {
  isPreferred: boolean
  phoneDeviceType: string
  phoneNumber: string
  phoneUsageType: PhoneTypeEnums | number | string
}

export type Experience = {
  isHidden?: boolean
  company: string
  companyId: Nullable<string>
  description: string
  dpExperience: boolean
  endDate: Nullable<Date>
  id: string
  isCurrent: boolean
  startDate: Nullable<Date>
  title: string
  industries: string[]
  offlimits: OffLimitsType
  offlimitsOverride: OfflimitsOverride
  duration: string
  compensation: Nullable<SalaryPackageType>
}

export type PersonTeamMember = TeamMember<Exclude<Membership, "owner">>

export type ExperienceFormValues = {
  company: string
  companyId: Nullable<string>
  description: string
  endDate: Nullable<Date>
  startDate: Nullable<Date>
  title: string
}

export enum EditPersonExperienceField {
  Title = "title",
  Company = "company",
  CompanyId = "companyId",
  StartDate = "startDate",
  EndDate = "endDate",
  Description = "description"
}

export const experienceDataInitialValues: ExperienceFormValues = {
  [EditPersonExperienceField.Title]: "",
  [EditPersonExperienceField.Company]: "",
  [EditPersonExperienceField.CompanyId]: "",
  [EditPersonExperienceField.StartDate]: new Date(),
  [EditPersonExperienceField.EndDate]: new Date(),
  [EditPersonExperienceField.Description]: ""
}

export type SalaryPackageType = {
  salary: { currency: string; value: number }
  package: { currency: string; value: number }
  comment: string
  updated?: Nullable<Date>
  updatedBy?: Nullable<UpdateSalaryType>
}

export type UpdateSalaryType = {
  firstName: string
  id: string
  lastName: string
}

export enum EditPersonSalaryPackageField {
  Salary = "salary",
  Package = "package",
  Comment = "comment"
}

export const salaryPackageInitialValues: SalaryPackageType = {
  [EditPersonSalaryPackageField.Salary]: { currency: "USD", value: 0 },
  [EditPersonSalaryPackageField.Package]: { currency: "USD", value: 0 },
  [EditPersonSalaryPackageField.Comment]: ""
}

export type PersonTeamUpdate = {
  userId: string
  membership: Exclude<Membership, "owner"> | null
}

export type RecentNoteType = {
  byFirstName: string
  byLastName: string
  noteTitle: string
  createdOrUpdated: Nullable<Date>
}

type Institute = {
  id: string
  name: string
}

export type Education = {
  id: string
  value: string
  institute: Institute
  startDate: Nullable<Date>
  endDate: Nullable<Date>
}

export type Person = {
  id: string
  dataPoolPersonId: string
  dataPoolId: string
  personId?: string
  name: string
  firstName: string
  lastName: string
  middleName: string
  createdDate: Date | string
  dateOfBirth: Nullable<Date>
  jobTitle: Nullable<string>
  linkedInProfileUrl: Nullable<string>
  location: Nullable<string>
  company: Nullable<string>
  companyId: Nullable<string>
  bio: Nullable<string>
  taggedEmails: TaggedEmail[]
  phones: PhoneDataType[]
  gdprLawfulBasisState: Nullable<PersonConsentsFormFields>
  keywords: Nullable<string[]>
  webSites: WebSite[]
  photo?: {
    url?: string
  }
  experience: Experience[]
  currentJob: Employment
  previousJobs: Employment[]
  education: Education[]
  stages: Stage[]
  statuses: Status[]
  rocketReachFetchedInformation?: boolean
  recentNote: RecentNoteType
  offlimits: OffLimitsType
  isOfflimits: boolean | undefined
  teamMembers: PersonTeamMember[]
  title: Nullable<string>
  knownAs: Nullable<string>
  nationality: Nullable<string>
  addressLine1: Nullable<string>
  addressLine2: Nullable<string>
  addressLine3: Nullable<string>
  addressLine4: Nullable<string>
  city: Nullable<string>
  country: Nullable<string>
  postcode: Nullable<string>
}

export type NoteDocument = {
  url?: string
  createdDate: Nullable<string | Date>
  filename: string
  id: string
  fileType: string
}

export enum CVPersonDetailsFields {
  Name = "name",
  Title = "title",
  FirstName = "firstName",
  LastName = "lastName",
  MiddleName = "middleName",
  KnownAs = "knownAs",
  Nationality = "nationality",
  DateOfBirth = "dateOfBirth",
  Bio = "bio",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  AddressLine3 = "addressLine3",
  AddressLine4 = "addressLine4",
  City = "city",
  Country = "country",
  PostCode = "postcode",
  Location = "location"
}

export type CVParsingString = {
  existing?: Nullable<string>
  parsed?: Nullable<string>
  isChecked?: boolean
  tempValue?: Nullable<string>
}

export type KeywordsCvList = {
  id: Nullable<string>
  value: string
  keywordCategoryId?: Nullable<string>
  isDeleted: boolean
}

export type CVParsingDate = {
  existing?: Nullable<Date>
  parsed?: Nullable<Date>
  isChecked?: boolean
  tempValue?: Nullable<Date>
}

export type CVPersonDetails = {
  [key: string]: any
  name: CVParsingString
  title: CVParsingString
  firstName: CVParsingString
  lastName: CVParsingString
  middleName: CVParsingString
  knownAs: CVParsingString
  nationality: CVParsingString
  dateOfBirth: CVParsingDate
  bio: CVParsingString
  addressLine1: CVParsingString
  addressLine2: CVParsingString
  addressLine3: CVParsingString
  addressLine4: CVParsingString
  city: CVParsingString
  country: CVParsingString
  postcode: CVParsingString
  location: CVParsingString
}

export type ContactInfoObject = {
  text?: string
  selectedValue?: string
  isPreferred?: boolean
}

export type ContactInfoEmailObject = {
  smtpValid?: boolean
} & ContactInfoObject

export type ContactInfoPhoneObject = {
  phoneDeviceType?: string
} & ContactInfoObject

export type CVContactInfoPhoneData = {
  existing?: ContactInfoPhoneObject
  parsed?: ContactInfoPhoneObject
  isChecked?: boolean
  isDeleted?: boolean
  tempValue?: ContactInfoPhoneObject
}

export type CVContactInfoEmailData = {
  existing?: ContactInfoEmailObject
  parsed?: ContactInfoEmailObject
  isChecked?: boolean
  isDeleted?: boolean
  tempValue?: ContactInfoEmailObject
}

export type CVContactInfoWebSite = {
  existing?: ContactInfoObject
  parsed?: ContactInfoObject
  isChecked?: boolean
  isDeleted?: boolean
  tempValue?: ContactInfoObject
}

export type CVContactInfo = {
  [key: string]: any
  phones: CVContactInfoPhoneData[]
  webSites: CVContactInfoWebSite[]
  taggedEmails: CVContactInfoEmailData[]
  linkedInProfileUrl: CVParsingString
}

export enum CVDataType {
  Parsed = "parsed",
  Existing = "existing"
}

export type CVDnDProps = {
  changedValues: Partial<Experience>
  type: CVDataType
}

export type ExistingExp = Experience &
  CVDnDProps & {
    isMovedToParsed: boolean
  }

export type ParsedExp = Experience &
  CVDnDProps & {
    isMovedToExisting?: boolean
  }

export type ParsedEducation = Education &
  CVDnDProps & {
    isMovedToExisting?: boolean
  }

export type ExistingEducation = Education &
  CVDnDProps & {
    isMovedToParsed: boolean
  }

export type CVProfessionalExperience = {
  existing: ExistingExp[]
  parsed: ParsedExp[]
  isToBeSync?: boolean
}

export type CVSkills = {
  existingKeywords: KeywordsCvList[]
  parsingKeywords: KeywordsCvList[]
  parsedEducation: ParsedEducation[]
  existingEducation: ExistingEducation[]
}

export type CVParsing = {
  personDetails: CVPersonDetails
  contactInfo: CVContactInfo
  professionalExperience: CVProfessionalExperience
  skills: CVSkills
}
