import styled from "styled-components"
import { Flex, H5 } from "@ikiru/talentis-fpc"

export const StyledCard = styled(Flex)<{ isDisabled: boolean }>`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.space.s}px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.standard};
  }
`

export const StyledH5 = styled(H5)`
  color: ${({ theme }) => theme.colors.green.standard};
  margin: 0;
`
