import React from "react"
import {
  PersonNoteField,
  personNotesFormE2ETargets
} from "views/persons/components/person-notes/form/constants/definitions"
import { messages } from "setup/messages/messages"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"

export const PersonNoteForm = () => {
  return (
    <>
      <FormikInput
        data-e2e-target-name={personNotesFormE2ETargets.noteTitle}
        id={PersonNoteField.NoteTitle}
        name={PersonNoteField.NoteTitle}
        label={messages.person.note.form.noteTitle}
      />

      <FormikTextarea
        data-e2e-target-name={personNotesFormE2ETargets.noteDescription}
        rows={8}
        id={PersonNoteField.NoteDescription}
        name={PersonNoteField.NoteDescription}
        label={messages.person.note.form.noteDescription}
      />
    </>
  )
}
