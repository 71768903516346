import React from "react"
import { Flex, SmallText, Tag } from "@ikiru/talentis-fpc"
import { PersonalNoteItemStyled } from "./styles"
import { ShowMore } from "components/ShowMore"
import { generateNoteDate } from "components/NoteItem/helpers"
import { Note } from "components/Notes/types"
import { formatDateTime } from "utils/format-date"

type PersonNoteProps = {
  note: Note
}

export const PersonNote = ({ note }: PersonNoteProps) => {
  const {
    noteTitle,
    noteDescription,
    createdDate,
    updatedDate,
    createdBy,
    updatedBy,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const isNoteUpdated = updatedBy && updatedDate

  return (
    <PersonalNoteItemStyled flexDirection="column">
      <Flex flexDirection="column" alignItems="flex-start">
        <Flex>
          <SmallText fontWeight="600" pr="10px" mb="0">
            Latest note:
          </SmallText>
          <Flex>
            <SmallText mr="xs" as="span" mb="0">
              {isNoteUpdated
                ? `Edited ${formatDateTime(updatedDate)}`
                : formatDateTime(createdDate)}
            </SmallText>
            <SmallText as="span" mb="0">
              {isNoteUpdated ? updatedBy : createdBy}
            </SmallText>
          </Flex>
        </Flex>
        <ShowMore
          visibleLines={1}
          isSmall
          title={noteTitle}
          description={noteDescription}
          hasBackground={false}
        />
        {(linkAssignment || linkCampaign) && (
          <Tag variant={linkAssignment ? "grey-dark" : "grey-standard"}>
            {linkAssignment?.name || linkCampaign?.name}
          </Tag>
        )}
      </Flex>
    </PersonalNoteItemStyled>
  )
}
