import * as Yup from "yup"
import { PersonConsentsField } from "views/persons/components/person-consents/form/constants/definitions"
import { gdprLawfulBasisOptions } from "views/persons/components/person-consents/form/constants/gdpr-lawful-basis"
import { GDPRStatusOption } from "views/persons/components/person-consents/form/constants/gdpr-statuses"
import { messages } from "setup/messages/messages"

const lawfulBasis = Yup.string().oneOf(
  gdprLawfulBasisOptions.map(({ value }) => value)
)

const originCharLimit = 50

const dataOrigin = Yup.string().max(
  originCharLimit,
  messages.person.consents.form.dataOriginError.format(originCharLimit)
)

export const initialPersonConsentValidationSchema = Yup.object({
  [PersonConsentsField.LawfulBasis]: lawfulBasis,
  [PersonConsentsField.DataOrigin]: dataOrigin
})

export const getPersonConsentValudationSchema = (
  gdprOptions: GDPRStatusOption[]
) => {
  const hasOptions = Boolean(gdprOptions.length)
  const GDPRStatus = Yup.string().oneOf(gdprOptions.map(({ value }) => value))
  return Yup.object({
    [PersonConsentsField.LawfulBasis]: lawfulBasis,
    [PersonConsentsField.DataOrigin]: dataOrigin,
    ...(hasOptions && { [PersonConsentsField.Status]: GDPRStatus })
  })
}
