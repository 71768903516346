import React from "react"
import { Flex, BodyText } from "@ikiru/talentis-fpc"
import { StyledCard, StyledH5 } from "./style"

type SearchCardProps = {
  cardImg: string
  title: string
  description: string
  onSelectCard: () => void
  isDisabled?: boolean
}

export const SearchCard = ({
  cardImg,
  title,
  description,
  onSelectCard,
  isDisabled = false
}: SearchCardProps) => {
  return (
    <Flex flexDirection="column" width="200px" mr="l">
      <StyledCard
        onClick={isDisabled ? undefined : onSelectCard}
        isDisabled={isDisabled}
      >
        <img
          alt={title}
          src={cardImg}
          //@ts-ignore
          width="196px"
          height="196px"
        />
        <StyledH5>{title}</StyledH5>
      </StyledCard>
      <BodyText>{description}</BodyText>
    </Flex>
  )
}
