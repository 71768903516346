import React from "react"
import { BodyText, Div, colors } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { OuterFlex } from "./style"
import { TutorialItem } from "../../TutorialItem"

const tutorialsData = [
  {
    videoUrl: "https://player.vimeo.com/video/933238824?h=480637506c",
    title: messages.supportForm.tutorialTitles.title15
  },
  {
    videoUrl: "https://player.vimeo.com/video/900114984?h=705c527ec8",
    title: messages.supportForm.tutorialTitles.title1
  },
  {
    videoUrl: "https://player.vimeo.com/video/975296832?h=0974fec1ee",
    title: messages.supportForm.tutorialTitles.title2
  },
  {
    videoUrl: "https://player.vimeo.com/video/899852397?h=cb3b2e4773",
    title: messages.supportForm.tutorialTitles.title3
  },
  {
    videoUrl: "https://player.vimeo.com/video/901535744?h=4614271a51",
    title: messages.supportForm.tutorialTitles.title4
  },
  {
    videoUrl: "https://player.vimeo.com/video/900097231?h=ca74ebe8f0",
    title: messages.supportForm.tutorialTitles.title5
  },
  {
    videoUrl: "https://player.vimeo.com/video/914733767?h=7d7aec6c92",
    title: messages.supportForm.tutorialTitles.title13
  },
  {
    videoUrl: "https://player.vimeo.com/video/900167828?h=1b720efd9d",
    title: messages.supportForm.tutorialTitles.title6
  },
  {
    videoUrl: "https://player.vimeo.com/video/924615562?h=1d8e13c816",
    title: messages.supportForm.tutorialTitles.title14
  },
  {
    videoUrl: "https://player.vimeo.com/video/901515369?h=7bfa6fd6ee",
    title: messages.supportForm.tutorialTitles.title7
  },
  {
    videoUrl: "https://player.vimeo.com/video/899762763?h=007c006f98",
    title: messages.supportForm.tutorialTitles.title8
  },
  {
    videoUrl: "https://player.vimeo.com/video/900826902?h=bf986417af",
    title: messages.supportForm.tutorialTitles.title9
  },
  {
    videoUrl: "https://player.vimeo.com/video/901459097?h=d8ce058abc",
    title: messages.supportForm.tutorialTitles.title10
  },
  {
    videoUrl: "https://player.vimeo.com/video/901107756?h=622dfab2a8",
    title: messages.supportForm.tutorialTitles.title11
  },
  {
    videoUrl: "https://player.vimeo.com/video/901119209?h=e8b005c33f",
    title: messages.supportForm.tutorialTitles.title12
  }
]

export const Tutorials: React.FC = () => {
  return (
    <Div width={["465px", "465px", "100%", "100%"]}>
      <BodyText my="s" ml="m" color={colors.grey.dark}>
        {messages.supportForm.tutorialInfo}
      </BodyText>

      {tutorialsData.map((item, index) =>
        index % 2 === 0 ? (
          <OuterFlex key={index}>
            <TutorialItem videoUrl={item.videoUrl} title={item.title} />
            {tutorialsData[index + 1] && (
              <TutorialItem
                videoUrl={tutorialsData[index + 1].videoUrl}
                title={tutorialsData[index + 1].title}
              />
            )}
          </OuterFlex>
        ) : null
      )}
    </Div>
  )
}
