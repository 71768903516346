import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import ViewableSection from "components/ViewableSection"

import { messages } from "setup/messages/messages"
import PersonDataPrivacyView from "./components/PersonDataPrivacyView"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonConsentsFormFields } from "./form/types"
import { getOnPersonConsentsSubmit } from "./actions"
import { PersonConsentsForm } from "./form"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { PersonSectionHeaders } from "../person-details-tab/style"
import FormActionButtons from "../person-details-tab/components/FormActionButtons"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

const PersonDataPrivacy = () => {
  const {
    personId,
    setProfilePrivacy,
    profilePrivacy: gdprLawfulBasisState,
    setPersonOverlayUpdated
  } = usePerson()
  const { createLocalPerson } = usePersonDataPoolLinkage()
  const { open: openModal, close } = useModal()

  const onSuccessfulSubmit = (consent: PersonConsentsFormFields) => {
    setProfilePrivacy(consent)
    setPersonOverlayUpdated(true)
    close(ModalNames.EditPersonDetails)
  }

  const onPersonConsentsSubmit = getOnPersonConsentsSubmit(
    personId,
    onSuccessfulSubmit,
    createLocalPerson
  )

  const editPersonDataPrivacy = () => {
    openModal(
      ModalNames.EditPersonDetails,
      <PersonConsentsForm
        onSubmit={onPersonConsentsSubmit}
        initialConsentState={gdprLawfulBasisState}
        formActionsComponent={() => (
          <FormActionButtons
            title={messages.person.personTabSection.dataPrivacy}
          />
        )}
      />
    )
  }
  return (
    <Div width="100%">
      <ViewableSection
        view={<PersonDataPrivacyView />}
        title={
          <PersonSectionHeaders>
            {messages.person.personTabSection.dataPrivacy}
          </PersonSectionHeaders>
        }
        editFunction={editPersonDataPrivacy}
      />
    </Div>
  )
}

export default PersonDataPrivacy
