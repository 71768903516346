export const isBrowserExtensionSupported = () =>
  window?.chrome?.runtime !== undefined

export const browserExtensionId =
  process.env.REACT_APP_BROWSER_EXTENSION_ID ||
  window.fpConfig.browserExtensionId

export const browserExtensionDownloadUrl =
  process.env.REACT_APP_BROWSER_EXTENSION_DOWNLOAD_URL ||
  window.fpConfig.browserExtensionDownloadUrl
