import cloneDeep from "lodash/cloneDeep"
import moment from "moment"
import { apiRequest } from "setup/api/api"
import { LocalSearchEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { ContactLookupFormValues } from "./components/ContactLookup/definitions"

const formatDateFields = (searchCriteria: any) => {
  return searchCriteria.map((item: any) => {
    const updatedFields = item.fields?.map((field: any) => {
      if (field.id === "dob" && Array.isArray(field.values)) {
        const formattedValues = field.values.map((date: any) =>
          moment(date).utc().startOf("day").format("YYYY-MM-DD HH:mm [+00:00]")
        )

        return {
          ...field,
          values: formattedValues
        }
      }

      return field
    })

    return {
      ...item,
      fields: updatedFields
    }
  })
}

export const getLocalSearchResult = async (
  searchCriteria: any,
  page?: number,
  pagesize?: number
) => {
  const tempSearchCriteria = cloneDeep(searchCriteria)

  const searchCriteriaSection = tempSearchCriteria.sections
    .filter((item: any) => item.fields && item.fields.length > 0)
    .map((item: any) => ({
      ...item,
      fields: item.fields
        .filter((field: any) => field !== null && field !== undefined)
        .filter((field: any) => {
          if (field.condition !== "notBlank" && field.condition !== "blank") {
            if (field.values && Array.isArray(field.values)) {
              const hasInvalidValue = field.values.some(
                (value: any) => value === null || value === ""
              )
              return !hasInvalidValue
            }
            return false
          }
          return true
        })
    }))
    .filter((item: any) => item.fields.length > 0)

  const searchCriteriaFormatted = formatDateFields(
    searchCriteriaSection
  ).filter((item: any) => item.fields && item.fields.length > 0)

  const [, response] = await apiRequest.post({
    endpoint: LocalSearchEndpoints.Root,
    data: {
      sections: searchCriteriaFormatted,
      page: page || 1,
      pagesize: pagesize || 25
    }
  })

  return response?.data
}

export const getLocalContactLookupResult = async (
  quickSearchValue: ContactLookupFormValues,
  page?: number,
  pagesize?: number
) => {
  const [, response] = await apiRequest.post({
    endpoint: LocalSearchEndpoints.Root,
    data: {
      ...quickSearchValue,
      page: page || 1,
      pagesize: pagesize || 25
    }
  })

  return response?.data
}

export const getLocalSearchSection = async () => {
  const [, response] = await apiRequest.get({
    endpoint: LocalSearchEndpoints.LocalSearchPropertiesSections,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data.sections
}

export const getLocalSearchFields = async (sectionId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: LocalSearchEndpoints.LocalSearchPropertiesFields,
    endpointParams: sectionId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getLocalSearchReferenceValue = async (columnId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: LocalSearchEndpoints.LocalSearchPropertiesReferenceValue,
    endpointParams: columnId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getLocalSearchReferenceEnums = async (columnId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: LocalSearchEndpoints.LocalSearchPropertiesEnums,
    endpointParams: columnId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}
