import { GDPRLawfulBasis } from "views/persons/components/person-consents/form/constants/gdpr-lawful-basis"

export enum PersonConsentsField {
  LawfulBasis = "gdprLawfulBasisOption",
  Status = "gdprLawfulBasisOptionsStatus",
  DataOrigin = "gdprDataOrigin"
}

export const initialDefaultPersonConsentState = {
  [PersonConsentsField.LawfulBasis]: GDPRLawfulBasis.None,
  [PersonConsentsField.DataOrigin]: ""
}
