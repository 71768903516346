import React from "react"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { Switch } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { useSearch } from "views/search/SearchModule/context"
import AssignmentSelector from "./AssignmentSelector"
import CampaignSelector from "./CampaignSelector"
import ModalTemplate from "./Components/AddNewModal/ModalTemplate"
import { SwitchLabel, StyledFlex } from "./style"

export type ProjectParams = {
  typeOfProject?: string
  projectId?: string
}
const ProjectSelector = () => {
  const {
    fetchAssignments,
    fetchCampaigns,
    setIsAssignmentSelect,
    isAssignmentSelect
  } = useSearch()

  const { open: openModal } = useModal()
  const addNew = () =>
    openModal(
      ModalNames.AddNewCampaignOrAssignment,
      <ModalTemplate
        isAssignmentSelect={isAssignmentSelect || true}
        fetchAssignments={fetchAssignments}
        fetchCampaigns={fetchCampaigns}
      />,
      true
    )

  if (isAssignmentSelect === null) {
    return null
  }

  return (
    <StyledFlex justifyContent="space-evenly" alignItems="center" height={30}>
      <Flex mx={["0", "xs"]} my={["xs"]}>
        <SwitchLabel isSelected={isAssignmentSelect}>
          {messages.talentGraphSearch.projectSelector.projectSwitch.assignment}
        </SwitchLabel>
        <Switch
          colorVariant="secondary"
          label={""}
          checked={!isAssignmentSelect}
          onChange={() => setIsAssignmentSelect(!isAssignmentSelect)}
        />
        <SwitchLabel isSelected={!isAssignmentSelect}>
          {messages.talentGraphSearch.projectSelector.projectSwitch.campaign}
        </SwitchLabel>
      </Flex>
      <Div height="m" width="150px">
        {isAssignmentSelect ? (
          <AssignmentSelector add={addNew} />
        ) : (
          <CampaignSelector add={addNew} />
        )}
      </Div>
    </StyledFlex>
  )
}

export default ProjectSelector
