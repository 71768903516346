import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { CustomBodyTexts, DataPrivacySubHeaders } from "./style"

const PersonDataPrivacyView = () => {
  const { profilePrivacy: gdprLawfulBasisState } = usePerson()

  const {
    gdprDataOrigin,
    gdprLawfulBasisOption,
    gdprLawfulBasisOptionsStatus
  } = gdprLawfulBasisState || {}

  const isGdprLawful = gdprLawfulBasisOption !== "none"

  const gdprOptions = messages.person.consents.form.options.basis
  const gdprStatusOptions = messages.person.consents.form.options.status
  return (
    <Flex flexWrap="wrap" py="xs">
      <Flex>
        <DataPrivacySubHeaders>
          {messages.person.consents.form.gdprLawfulBasis}
        </DataPrivacySubHeaders>
        <CustomBodyTexts>
          {gdprOptions[isGdprLawful ? gdprLawfulBasisOption! : "notSet"]}
        </CustomBodyTexts>
      </Flex>
      {Boolean(gdprLawfulBasisOptionsStatus) && (
        <Flex width="100%" mt="xs">
          <DataPrivacySubHeaders>
            {messages.person.consents.form.statusLabel}
          </DataPrivacySubHeaders>
          <CustomBodyTexts>
            {gdprStatusOptions[gdprLawfulBasisOptionsStatus!]}
          </CustomBodyTexts>
        </Flex>
      )}
      {Boolean(gdprDataOrigin) && (
        <Flex width="100%" mt="xs">
          <DataPrivacySubHeaders>
            {messages.person.consents.form.dataOriginLabel}
          </DataPrivacySubHeaders>
          <CustomBodyTexts>{gdprDataOrigin}</CustomBodyTexts>
        </Flex>
      )}
    </Flex>
  )
}

export default PersonDataPrivacyView
